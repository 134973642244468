import '@datadog/browser-rum/bundle/datadog-rum';
import type { RumGlobal } from '@datadog/browser-rum';
import { DATADOG_APPLICATION_ID, DATADOG_CLIENT_TOKEN } from '../../settings';

declare global {
  interface Window {
    DD_RUM: RumGlobal;
  }
}

class DatadogService {
  initialize(id: string, type: string) {
    window.DD_RUM.onReady(() => {
      window.DD_RUM.init({
        applicationId: DATADOG_APPLICATION_ID!,
        clientToken: DATADOG_CLIENT_TOKEN!,
        site: 'datadoghq.com',
        service: 'cart',
        env: import.meta.env.DEV ? 'develop' : 'live',
        sessionSampleRate: 80,
        sessionReplaySampleRate: 0,
        trackUserInteractions: true,
        trackResources: true,
        trackLongTasks: true,
        defaultPrivacyLevel: 'mask-user-input',
        enableExperimentalFeatures: ['feature_flags'],
      });
      window.DD_RUM.setUser({ id, type: type === 'ConsumerUser' ? 'consumer' : 'retailer' });
    });
  }

  private throttle(func: (...args: any[]) => void, limit: number) {
    let lastFunc: ReturnType<typeof setTimeout>;
    let lastRan: number;
    return (...args: any[]) => {
      if (!lastRan) {
        func.apply(this, args);
        lastRan = Date.now();
      } else {
        clearTimeout(lastFunc);
        lastFunc = setTimeout(
          () => {
            if (Date.now() - lastRan >= limit) {
              func.apply(this, args);
              lastRan = Date.now();
            }
          },
          limit - (Date.now() - lastRan),
        );
      }
    };
  }

  triggerEvent = this.throttle((code: string, attributes?: Record<string, any>) => {
    window.DD_RUM.addAction(code, attributes);
  }, 2000);

  cartAddSuccess() {
    this.triggerEvent('web.cart_add_success');
  }
  purchase() {
    this.triggerEvent('web.purchase');
  }
  retantionPurchase() {
    this.triggerEvent('web.retention_purchase');
  }
  acquisitionPurchase() {
    this.triggerEvent('web.acquisition_purchase');
  }
}

export const datadogService = new DatadogService();
