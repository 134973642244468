import { DidomiSDK, IDidomiObject } from '@didomi/react';
import { DIDOMI_AMPLITUDE_CONSENT_ID, DIDOMI_API_KEY, DIDOMI_CONSENT_ID } from '../../../settings';
import { Amplitude as amplitude, hasDataDogCookie } from '../../utils';
import { useGetUserType } from '../../hooks/useGetUserType';
import { useEffect, useState } from 'react';
import { datadogService } from '../../utils/DatadogService';

export const CookieConsent = () => {
  const [isDidomiReady, setIsDidomiReady] = useState(false);
  const { isSuccess, data, isRetailerUser } = useGetUserType();

  useEffect(() => {
    if (isDidomiReady) {
      if (!hasDataDogCookie() && isSuccess) {
        amplitude.init({ userId: data?.id || 'unknown', userType: isRetailerUser ? 'retailer' : 'consumer' });
        datadogService.initialize(data?.id.toString() || 'unknown', isRetailerUser ? 'RetailerUser' : 'ConsumerUser');
      }
    }
  }, [isDidomiReady, isSuccess, data, isRetailerUser]);

  const handleDidomiReady = (didomi: IDidomiObject) => {
    didomi.getUserConsentStatusForAll().vendors.enabled.includes(DIDOMI_AMPLITUDE_CONSENT_ID as never);
    setIsDidomiReady(true);
  };

  return (
    <DidomiSDK
      apiKey={DIDOMI_API_KEY}
      noticeId={DIDOMI_CONSENT_ID}
      iabVersion={2}
      embedTCFStub={true}
      gdprAppliesGlobally={true}
      onReady={handleDidomiReady}
    />
  );
};
